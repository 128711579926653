import * as React from 'react'
import Layout from '../components/layout'
import {Row, Col, Container} from "react-bootstrap" 
import { Link } from "gatsby"

const WorkPage = () => {
  return (
    <Layout pageTitle="Work">
      <hr/>
      <Container><p>All Projects</p></Container>
      <hr/>
      <br/>
      <div className='home'>
        <Container>
          <Row>
            <Col sm={6} className='mt-5'>
              <Link to='/work/sdlg'>
                <Row>
                  <h1 className='work'>SDLG</h1>
                </Row>
                <Row>
                  <h2>Generating logos with deep learning.</h2>
                  <p>View →</p>
                </Row>
              </Link>
            </Col>
            <Col sm={6} className='mt-5'>
            <Link to='/work/antidepress-bots'>
              <Row>
                <h1 className='work'>Antidepress Bots</h1>
              </Row>
              <Row>
                <h2>Bot-network against depressive content on social media.</h2>
                <p>View →</p>
              </Row>
            </Link>
            </Col>
            <Col sm={6} className='mt-5'>
            <Link to='/work/beyond-advertising'>
              <Row>
                <h1 className='work'>Beyond Advertising</h1>
              </Row>
              <Row>
                <h2>WDKA internship platform during the pandemic.</h2>
                <p>View →</p>
              </Row>
            </Link>
            </Col>
            <Col sm={6} className='mt-5'>
            <Link to='/work/pitapat'>
              <Row>
                <h1 className='work'>Pit.a.pat</h1>
              </Row>
              <Row>
                <h2>Listening landscape created for the Cinekid MediaLab.</h2>
                <p>View →</p>
              </Row>
            </Link>
            </Col>
            <Col sm={6} className='mt-5'>
            <Link to='/work/scarcities'>
              <Row>
                <h1 className='work'>Scarcities</h1>
              </Row>
              <Row>
                <h2>Mapping the use of space with Artificial Intelligence.</h2>
                <p>View →</p>
              </Row>
            </Link>
            </Col>
            <Col sm={6} className='mt-5'>
            <Link to='/work/eaternet'>
              <Row>
                <h1 className='work'>Eaternet</h1>
              </Row>
              <Row>
                <h2>Product design for the overconsumption of media + junkfood.</h2>
                <p>View →</p>
              </Row>
            </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
export default WorkPage